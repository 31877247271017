/* Product.css */
.home-page {
    background-color: #FFF7E6;
    padding: 20px;
  }
  
  .home-page h1 {
    color: #5D4037;
  }
  
  .home-page p {
    color: #3E2723;
  }

    
 div.product-list {
  padding: 0px; 
 }
  
 .product-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(45%, 1fr)); /* Forces at least two items per row */
    gap: 10px;
    list-style-type: none;
    padding: 0px; 
    width: 100%; /* Ensures the list spans full container */
    margin-bottom: 100px;
  }
  
  .product-list li {
    background-color: #f4f4f4; /* Styling to visualize */
    border: 1px solid #ddd; /* Optional styling */
    padding: 10px;
    text-align: center;
  }
  
  .product-list img {
    width: 100%;
    border-radius: 10px;
  }
  
  .product-list h3 {
    color: #5D4037;
  }
  
  .product-list p {
    color: #3E2723;
    font-weight: bold;
  }
  
  .product-list button {
    background-color: #FFC107;
    color: #3E2723;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .product-list button {
    background-color: #FFC107;
    color: #3E2723;
    padding: 12px 20px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    font-size: 16px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    margin-top: 10px;
  }
  
  .product-list button:hover {
    background-color: #FFB300;
    color: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  
  .product-list button:focus {
    outline: none;
    border: 2px solid #FFB300;
    box-shadow: 0 0 10px rgba(255, 179, 0, 0.7);
  }
  
  .product-list button:active {
    background-color: #FF8F00;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

 
/* Filter container styles */
.filter-container {
  position: relative;
  display: block;
  text-align: left;
  margin-top: 10px;
  padding: 10px 0;
  margin-bottom: -10px;
}

.filter-icon {
  background: none;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #5D4037; /* Matches home-page text color */
  position: relative;
  margin-top: -100px;
}

.filter-icon svg {
  height: 18px;
  width: 18px;
  position: absolute;
  left: 12px;
  bottom: 8px;
}

/* Ensuring the dropdown aligns properly */
.filter-menu {
  position: absolute;
  background-color: #FFF7E6; /* Matches page background */
  border: 1px solid #d2b48c; /* Matches subtle styling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.filter-menu button {
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px; /* Adjust button text size */
  color: #5D4037;
}
  
.filter-menu button:hover {
  background-color: #d2691e;
  color:#f6f5f4
}
  
  
  @media (min-width: 768px) {
    .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40%, 4fr)); /* Forces at least two items per row */
      gap: 10px;
      list-style-type: none;
      padding: 40px;
      margin: 0;
      width: 100%; /* Ensures the list spans full container */
      margin-bottom: 100px;
      margin-top: 25px;
      position: relative;
      margin: auto;
    }

    .product-list button {
      background-color: #FFC107;
      color: #3E2723;
      padding: 12px 20px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 8px;
      font-size: 18px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    }
    .product-list h2 {
      color: #5D4037;
      font-size: 26px;
      margin-bottom: 5px;
    }
    .product-list p {
      color: #27201e;
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  @media (min-width: 1024px) {
    .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(25%, 5fr)); /* Forces at least two items per row */
      gap: 10px;
      list-style-type: none;
      padding: 40px;
      margin: 0;
      width: 75%; /* Ensures the list spans full container */
      margin-bottom: 100px;
      margin-top: 25px;
      position: relative;
      margin: auto;
    }

    .product-list button {
      background-color: #FFC107;
      color: #3E2723;
      padding: 12px 20px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 8px;
      font-size: 18px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    }
    .product-list h2 {
      color: #5D4037;
      font-size: 26px;
      margin-bottom: 5px;
    }
    .product-list p {
      color: #27201e;
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
    }

     /* Filter container styles */
.filter-container {
  position: relative;
  display: block;
  text-align: left;

  padding: 10px 0;
}

.filter-icon {
  background: none;
  border: none;
  cursor: pointer;
  height: 40px;
  width: 40px;
  color: #5D4037; /* Matches home-page text color */
  position: relative;
  margin-top: -100px;
  margin-left: 130px;
}

.filter-icon svg {
  height: 18px;
  width: 18px;
  position: absolute;
  left: 12px;
  bottom: 8px;
  margin-top: -170px;
}

/* Ensuring the dropdown aligns properly */
.filter-menu {
  position: absolute;
  background-color: #FFF7E6; /* Matches page background */
  border: 1px solid #d2b48c; /* Matches subtle styling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-left: 130px;
}

.filter-menu button {
  padding: 10px;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 18px; /* Adjust button text size */
  color: #5D4037;
}
  
.filter-menu button:hover {
  background-color: #d2691e;
  color:#f6f5f4
}
  }

 

  @media (min-width: 1440px) {
    .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20%, 5fr)); /* Forces at least two items per row */
      gap: 10px;
      list-style-type: none;
      padding: 40px;
      margin: 0;
      width: 60%; /* Ensures the list spans full container */
      margin-bottom: 100px;
      margin-top: 25px;
      position: relative;
      margin: auto;
    }

    .product-list button {
      background-color: #FFC107;
      color: #3E2723;
      padding: 20px 30px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 8px;
      font-size: 22px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    }
    .product-list h2 {
      color: #5D4037;
      font-size: 26px;
      margin-bottom: 5px;
    }
    .product-list p {
      color: #27201e;
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    .filter-icon {
      background: none;
      border: none;
      cursor: pointer;
      height: 40px;
      width: 40px;
      color: #5D4037; /* Matches home-page text color */
      position: relative;
      margin-top: -150px;
      margin-left: 290px;
    }
    /* Ensuring the dropdown aligns properly */
.filter-menu {
  position: absolute;
  background-color: #FFF7E6; /* Matches page background */
  border: 1px solid #d2b48c; /* Matches subtle styling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-left: 290px;
}
  }

  @media (min-width: 1800px) {
    .product-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20%, 5fr)); /* Forces at least two items per row */
      gap: 10px;
      list-style-type: none;
      padding: 40px;
      margin: 0;
      width: 60%; /* Ensures the list spans full container */
      margin-bottom: 100px;
      margin-top: 25px;
      position: relative;
      margin: auto;
    }

    .product-list button {
      background-color: #FFC107;
      color: #3E2723;
      padding: 20px 30px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 8px;
      font-size: 22px;
      transition: all 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-transform: uppercase;
    }
    .product-list h2 {
      color: #5D4037;
      font-size: 26px;
      margin-bottom: 5px;
    }
    .product-list p {
      color: #27201e;
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
    }

    .filter-icon {
      background: none;
      border: none;
      cursor: pointer;
      height: 40px;
      width: 40px;
      color: #5D4037; /* Matches home-page text color */
      position: relative;
      margin-top: -100px;
      margin-left: 475px;
    }
    /* Ensuring the dropdown aligns properly */
.filter-menu {
  position: absolute;
  background-color: #FFF7E6; /* Matches page background */
  border: 1px solid #d2b48c; /* Matches subtle styling */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  margin-left: 475px;
}
  }


