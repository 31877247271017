#payment-status-container {
    padding: 1rem;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    font-size: 1rem;
    text-align: center;
    margin: 1.5rem 0;
    width: 100%;
  }
  
  #payment-status-container.is-success {
    background-color: #d4edda; /* Light green */
    color: #155724; /* Dark green text */
    border: 1px solid #c3e6cb; /* Green border */
  }
  
  #payment-status-container.is-failure {
    background-color: #f8d7da; /* Light red */
    color: #721c24; /* Dark red text */
    border: 1px solid #f5c6cb; /* Red border */
  }
  
  /* Additional tweaks for responsiveness */
  #payment-status-container p {
    margin: 0;
    padding: 0.5rem 0;
  }
  
  @media (min-width: 1200px) {
    
    #payment-status-container.is-success {
      background-color: #d4edda; /* Light green */
      color: #155724; /* Dark green text */
      border: 1px solid #c3e6cb; /* Green border */
      font-size: 20px;
    }
    
    #payment-status-container.is-failure {
      background-color: #f8d7da; /* Light red */
      color: #721c24; /* Dark red text */
      border: 1px solid #f5c6cb; /* Red border */
      font-size: 20px;
    }
    
  }