/* Common Navbar Styles */

/* src/App.css */
.banner {
  background-color: #f44336; /* Red background for emphasis */
  color: white;
  padding: 10px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; /* Ensure it's above other content */
}

.page-container {
  display: flex;
  flex-direction: column; /* Ensures a vertical layout */
}

.main-content {
  padding: 3rem; /* Space around main content */
}

.navbar {
  height: 63px;
}

.header {
  display: flex;
  justify-content: space-between;
  background-color: white; /* Soft beige */
}

.header-left {
  height: 20px;
  position: relative;
  left: 50%;
}

.header-left .logo {
  height: 157px;
  width: 157px;
  margin-right: 35px;
}

.header-right {
  display: inline-block;
  min-width: 10px;      
  height: 100%;   
  position: relative;
}

/* Cart Container */
.cart-container {
  text-decoration: none; /* Ensure no underline applied globally */
  position: absolute;
  right: 15px;
}

.cart-info {
  position: absolute;
  top: 10px; 
  left: 50%;  
  transform: translate(-50%, -100%);
  width: max-content; 
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(220, 226, 205); 
  padding: 2px 5px; 
  border-radius: 4px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  pointer-events: none; 
}

.cart-totals {
  font-size: 11px;
  color: #0c0a0a;
  text-align: center;
  font-weight: bold;
}

.cart-link {
  text-decoration: none;
}

.main-content .sidebar-container .menu-button {
  margin-top: 45.5px;
}

.sidebar-container .menu-button {
  margin-top: -1.5px;
}

/* Full-Width Navbar for Desktop View */
.navbar-links {
  display: flex;
  justify-content: center;
  list-style: none;
  margin-top: 140px;
  padding: 10px 0;
  background-color: #ffffff;
  width: 100%;
  font-size: 18px;
  margin-left: 5px;
}

.navbar-links li {
  margin: 0 50px;
  color: #5A3E36;
}

.navbar-links li.about {
  margin-left: 10px;
}

.navbar-links li a {
  text-decoration: none;

  font-weight: bold;
  transition: color 0.3s ease;
}

li a:hover {
  color: #f5a03e;
}

/* Media Queries */
@media (max-width: 769px) {
  .navbar {
      flex-direction: column; /* Separate header from navbar */
      margin-top: 0px; /* Ensure the navbar aligns below the header */
      display: none;
  }

  .header {
      justify-content: space-between;
      padding: 10px 30px;
      margin-bottom: 25px;
  }

  .header-left {
    height: 25px;
    position: relative;
    left: 0%;
  }
  
  .header-left .logo {
    height: 65px;
    width: 65px;
    margin-top: -10px;
    margin-left: -15px;
    border-radius: 2px;
  }
}

@media (min-width: 770px) {
  .header-left {
    transform: translateX(-35%);
  }

  .header-right {
    min-width: 10px;      
    height: 100%;   
    position: relative; 
    right: 50px;
    top: 10px;     
  }

  .cart-totals {
    font-size: 15px;
    color: #0c0a0a;
    text-align: center;
    font-weight: bold;
  }

  .sidebar-container {
      display: none; /* Hide Sidebar in desktop view */
  }

  .cart-container {
    left: -50px;
    top: 5px;
  }

  .navbar-links {
      display: flex; /* Full navbar links for desktop */
      font-size: 24px;
  }
}

@media (min-width: 1024px) {
  .header-right {
    min-width: 10px;      
    height: 100%;   
    position: relative; 
    right: 200px;
    top: 10px;     
  }

  .cart-totals {
    font-size: 15px;
    color: #0c0a0a;
    text-align: center;
    font-weight: bold;
  }

  .navbar-links {
    display: flex;
    justify-content: center;
    list-style: none;
    margin-top: 140px;
    padding: 10px 0;
    background-color: #ffffff;
    width: 100%;
    font-size: 24px;
    margin-left: 35px;
  }

  .navbar-links li {
    margin-right: 100px;
  }
} 

@media (min-width: 1440px) {
  .header-right {
    min-width: 10px;      
    height: 100%;   
    position: relative; 
    right: 250px;
    top: 10px;     
  }

.cart-info {
  position: absolute;
  top: 10px; 
  left: 50%;  
  transform: translate(-50%, -100%);
  width: max-content; 
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: rgb(220, 226, 205); 
  padding: 2px 5px; 
  border-radius: 4px; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  pointer-events: none; 
}

.cart-totals {
  font-size: 15px;
  color: #0c0a0a;
  text-align: center;
  font-weight: bold;
}

}