/* src/features/about/AboutPage.css */
.about-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .about-page h1 {
    text-align: center;
    color: #333;
    font-size: 2.5rem; 
  }

  .about-page h2 {
    margin-bottom: 5px;
  }
  
  .about-story,
  .about-mission,
  .about-why-choose-us {
    margin-bottom: 10px;
    margin-top: 25px;
  }
  
  .about-mission p,
  .about-story p {
    font-size: 18px;
    line-height: 1.6;
    color: #555;
  }
  
  .about-why-choose-us ul {
    list-style-type: disc;
    margin-left: 20px;
    font-size: 16px;
    list-style-position: inside; /* Moves the bullet points inside the text flow */
    padding-left: 0; /* Removes extra left padding */
  }
  
  .about-why-choose-us li {
    margin-bottom: 10px;
    margin-right: 15px; /* Adds space between each bullet item */
  }
  

  @media (min-width: 1200px) {
    /* src/features/about/AboutPage.css */
.about-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.about-page h1 {
  text-align: center;
  color: #333;
  font-size: 2.5rem; 
}

.about-page h2 {
  text-align: center;
  font-size: 25px;
}

.about-story,
.about-mission,
.about-why-choose-us {
  margin-bottom: 20px;
  margin-top: 35px;
}

.about-mission p,
.about-story p {
  font-size: 23px;
  line-height: 1.6;
  color: #555;
}

.about-why-choose-us ul {
  list-style-type: disc;
  margin-left: 20px;
  font-size: 23px;
  list-style-position: inside; /* Moves the bullet points inside the text flow */
  padding-left: 0; /* Removes extra left padding */
}

.about-why-choose-us li {
  margin-bottom: 10px;
  margin-right: 15px; /* Adds space between each bullet item */
}
  }