/* src/pages/OptionsOrderForm/OptionsOrderForm.css */
.options-order-container {
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}

.options-order-form {
    position: fixed;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%) !important;
    max-width: 90vw;
    width: 70%;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.3);
    padding: 20px;
    max-height: 80vh;
    overflow-y: auto;
    transition: transform 0.3s ease-in-out;
}

.Cupcake.layer-size, .Cookie.layer-size {
    display: none;
}

.Cupcake.buttercream, .Cookie.buttercream {
    display: none;
}

.Cookie.flavor {
    display: none;
}

 .Cake.filling, .Cookie.filling {
    display: none;
}

.Cupcake.message, .Cookie.message {
    display: none;
}

.options-order-form.visible {
    transform: translateY(0);
}

h2 {
    text-align: center;
    color: #5A3E36;
    font-family: 'Pacifico', cursive;
    margin-bottom: -12px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    font-weight: bold;
    color: #5A3E36;
}

select, input, textarea {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.button-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: transform 0.2s ease, color 0.2s ease;
}

.button-close:hover {
    color: #ff4d4d; /* Stylish red on hover */
    transform: rotate(90deg); /* Add a rotation effect */
}

.button-close:active {
    transform: scale(0.9); /* Slightly shrink on click */
  }

.button-group {
    display: flex;
    justify-content: space-between;
}

.add-to-cart, .buy-now {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart {
    background-color: #FFD54F;
    color: #5A3E36;
}

.add-to-cart:hover {
    background-color: #FFC107;
}

.buy-now {
    background-color: #FF7043;
    color: white;
}

.buy-now:hover {
    background-color: #FF5722;
}


@media (min-width: 768px) {
.options-order-form {
    width: 50%;
    }
}