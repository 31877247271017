.cookies-section {
    text-align: center;
    padding: 2rem;
   
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 90%;
    margin: 0.5rem auto;
  }
  
  .cookies-title {
    font-size: 2.5rem;
    color: #d2691e; /* Warm chocolatey color */
    font-family: 'Georgia', serif;
    margin-bottom: 0.5rem;
  }
  
  .cookies-description {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    font-family: 'Arial', sans-serif;
  }
  
  @media (min-width: 1024px) {
    .cookies-title {
      font-size: 2.5rem;
      color: #d2691e; /* Warm chocolatey color */
      font-family: 'Georgia', serif;
      margin-bottom: 0.5rem;
    }

    .cookies-description {
      font-size: 1.5rem;
      color: #555;
      line-height: 1.6;
      font-family: 'Arial', sans-serif;
      font-weight: bold;
    }
  }
  