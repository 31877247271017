.contact-us-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh; /* This makes sure the container takes full height */
    text-align: center; /* Center the text inside */
    padding: 20px;
  }
  
  .contact-us-container h1 {
    font-size: 2.5rem; /* Adjust the font size of the heading as needed */
    margin-bottom: 20px;
  }
  
  /* Optional: Apply margin to form */
  form {
    margin-top: 40px;
  }
  
  /* Responsive styling to ensure form is also centered in smaller viewports */
  @media (max-width: 768px) {
    .contact-us-container {
      padding: 10px;
    }
  
    form {
      padding: 15px;
    }
  }
  