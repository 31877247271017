/* src/components/Footer/Footer.css */

.footer {
  background-color: #333; /* Dark background */
  color: #fff;           /* White text */
  padding: 20px 0;
  text-align: center;
  font-family: Arial, sans-serif;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.footer-logo img {
  width: 60px; /* Adjust the logo size */
  height: 60px;
  border-radius: 10px;
}

.footer-nav {
  margin-top: 10px;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 15px;
}

.footer-links li {
  margin: 0;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
}

.footer-links a:hover {
  color: #ff9800; /* Highlight color on hover */
}

.footer-contact {
  font-size: 14px;
}

.footer-contact a {
  color: #ff9800; /* Email link color */
  text-decoration: none;
}

.footer-contact a:hover {
  text-decoration: underline;
}

.footer-copyright {
  margin-top: 20px;
  font-size: 12px;
  border-top: 1px solid #555; /* Top border for separation */
  padding-top: 10px;
}

@media (min-width: 768px) {

  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
  }

  .footer-links a { 
    font-size: 18px;
  }
  .footer-contact {
    font-size: 18px;
  }

  .footer-copyright {
    margin-top: 20px;
    font-size: 16px;
    border-top: 1px solid #555; /* Top border for separation */
    padding-top: 10px;
  }
}

@media (min-width: 1024px) {

  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 30px;
  }

  .footer-links a { 
    font-size: 20px;
  }
  .footer-contact {
    font-size: 20px;
  }

  .footer-copyright {
    margin-top: 20px;
    font-size: 18px;
    border-top: 1px solid #555; /* Top border for separation */
    padding-top: 10px;
  }
}